import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Link コンポーネントをインポート
import './EventList.css';

function EventList() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://4zpzmvcch9.execute-api.ap-northeast-1.amazonaws.com/prod/events');
        if (!response.ok) {
          throw new Error('Something went wrong');
        }
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="reviewList">
      <h1>公演一覧</h1>
      {/* button タグを Link でラップし、全体をクリッカブルに */}
      <Link to="/addevent" className="addEventButton">
        <button>公演を追加</button>
      </Link>
      <ul>
        {events.length > 0 ? (
          events.map(event => (
            <li key={event.id}>
              <Link to={`/event/${event.id}`}>
                <h2>{event.event_name}</h2>
                <p>Rating: {event.rating} / 5.0</p>
                <p>{event.summary}</p>
              </Link>
            </li>
          ))
        ) : (
          <p>No events found</p>
        )}
      </ul>
    </div>
  );
}

export default EventList;
