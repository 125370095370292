import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav>
      <div className="logo">
        <Link to="/">謎解きポータル</Link> {/* ホームページへのリンクとロゴ */}
      </div>
      <div className="hamburger-menu" onClick={() => setIsOpen(!isOpen)}>
        <span>メニュー</span> {/* ハンバーガーメニューアイコン */}
      </div>
      <ul className={`nav-links ${isOpen ? "show" : ""}`}>
        <li><Link to="/events">公演一覧</Link></li>
        <li><Link to="/reviews">最新のレビュー</Link></li>
        <li><Link to="/categories">謎作成団体一覧</Link></li>
        <li><Link to="/mypage">マイページ</Link></li>
        <li><Link to="/contact">お問い合わせ</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
