import React from 'react';
import './HomePage.css'; // CSSファイルをインポート

function HomePage() {
  return (
    <div className="homePage">
      <h1>謎解きポータルへようこそ！</h1>
      <p>謎解き公演のレビューサイトです</p>

      <section className="featuredReviews">
        <h2>注目の公演</h2>
        <p>直近一ヶ月間で評価の高い公演です。</p>
        {/* ここに注目のレビューを表示するコンポーネントを追加することができます */}
      </section>

      <section className="latestNews">
        <h2>最新ニュース</h2>
        <p>comming soon</p>
        {/* 最新ニュースを表示するコンポーネントをここに追加 */}
      </section>
    </div>
  );
}

export default HomePage;
